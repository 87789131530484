@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulsateBig {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.pulsating-logo {
  width: 120px; /* Adjust the size as needed */
  height: 120px; /* Adjust the size as needed */
  animation: pulsate 1.5s infinite;
}

.slight-shadow {
  text-shadow: black 1px 0 10px;
}

.scroll-down-indicator {
  position: absolute;
  right: 50%;
  top: 80%;
  color: white;
  transform: translateX(50%);
  animation: pulsateBig 1.5s infinite;
}

.button-open {
  text-decoration: underline !important;
  text-transform: capitalize !important;

}
